import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
    Contatto,
    EOperatore,
    GetPageFooterResponse,
    MenuData,
	PageContact,
	PageData,
	PageDataGeneric,
    PageLinkDetails
} from 'nr-common';
import { environment } from 'projects/nr-b2c/src/environments/environment';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'nr-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
	public menuVoices: MenuData[];
	public footerData: PageData = null;
	public footerContact: PageContact = null;

	public readonly COLUMN_GENERAL: number = 1;
	public readonly COLUMN_INFO: number = 2;

	private footerLinkId: number = -1;
	private subscriptions = new Subscription();

	constructor(
		private apiService: ApiService,
		public webSiteService: WebsiteService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		// Subscribe to the current language change
		this.subscriptions.add(
			this.translateService.onLangChange.subscribe(() => {
				// Force reload of the footer content
				this.loadFooterContent(true);
			})
		);

		this.loadFooterContent();
	}

	private loadFooterContent(force: boolean = false) {
		const newFooterLink = this.webSiteService.getFooterLinkId();
		if (force || this.footerData == null || newFooterLink != this.footerLinkId) {
			this.footerLinkId = newFooterLink;
			if (this.footerLinkId == null || this.footerLinkId <= 0) {
				this.footerData == null;
				return;
			}

			this.subscriptions.add(
				this.apiService.getPageFooter(this.footerLinkId).subscribe((data: GetPageFooterResponse) => {
					this.footerData = data.footerData;
					this.footerContact = data.contactInfo;
				})
			);
		}
	}

	public hasFooterContact(): boolean {
		return this.footerContact != null;
	}

	public hasFax(): boolean {
		return this.footerContact != null && this.footerContact.fax != null;
	}

	public hasPhone(): boolean {
		return this.footerContact != null && this.footerContact.telefono != null;
	}

	public hasEmail(): boolean {
		return this.footerContact != null && this.footerContact.email != null;
	}

	public getArticleLink(id: number): string {
		return `/article/${id}`;
	}

	public getArticleName(name: string): string {
		return name;
		// Spengo la funzione che metteva in maiuscolo SOLO la prima lettera.
		// Lasciando TOTALMENTE in balia della stesura dell'articolo il CASE dello stesso
		// name = '' + name;
		// return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	}

	public getFooterColumn(columIndex: number): PageDataGeneric[] {
		if (this.footerData == null) return [];

		let result: PageDataGeneric[] = [];
		if (this.footerData.elencoDati) {
			result = this.footerData.elencoDati.filter((e) => {
				if (e.group === columIndex) {
					return e;
				}
			});
		}

		return result;
	}

	public isPrivacyLink(item: PageDataGeneric): boolean {
		return item.entityId == environment.DINAMIC_COOKIES_ARTICLE_ID;
	}

	/* openPrivacyLink(item: PageDataGeneric) {
		const url: string = `/article/${item.entityId}`;
		window.open(url, "_blank");
	} */

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	// Prmette la visualizzazione dei social link solo per l'operatore Naar
	// TODO:   Da verificare se è possibile fare un controllo
	// TODO:   che gestisca i link social dei vari operatori
	// TODO:   attivabili tramite i dati aggiuntivi
	// TODO:   tipoDatoAggiuntivo: 95 - Social Link
	public hasSocialLinks(): boolean {
		return this.webSiteService.currentWebsite.operatore === EOperatore.Naar;
	}
}
